import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import {
  addNewsImages,
  createDraftNewsItem,
  deleteNewsImage,
  deleteNewsItem,
  getNewsItem,
  getNewsList,
  hideNews,
  publishNews,
  updateNewsItem
} from './news.actions'
import { NewsService } from './news.service'
import { NotifyService } from 'admin/src/app/services/notify.service'

@Injectable()
export class NewsEffects {
  getNewsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getNewsList.start),
      mergeMap(({ page }) =>
        this.newsService.getNewsList(page).pipe(
          map(response => getNewsList.success({ response })),
          catchError(error => of(getNewsList.error({ error })))
        )
      )
    )
  )

  createDraftNewsItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createDraftNewsItem.start),
      mergeMap(({ news }) =>
        this.newsService.createDraftNewsItem(news).pipe(
          map(news => createDraftNewsItem.success({ news })),
          catchError(error => of(createDraftNewsItem.error({ error })))
        )
      )
    )
  )

  updateNewsItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateNewsItem.start),
      mergeMap(({ data, oldNewsItem }) =>
        this.newsService.updateNewsItem(data, oldNewsItem).pipe(
          map(news => updateNewsItem.success({ news })),
          catchError(error => of(updateNewsItem.error({ error })))
        )
      )
    )
  )

  deleteNewsItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteNewsItem.start),
      mergeMap(({ id }) =>
        this.newsService.deleteNewsItem(id).pipe(
          map(() => {
            this.notifyService.success('Новость удалена')
            return deleteNewsItem.success({ id })
          }),
          catchError(error => of(deleteNewsItem.error({ error })))
        )
      )
    )
  )

  addNewsImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addNewsImages.start),
      mergeMap(({ id, images }) =>
        this.newsService.addNewsImages(id, images).pipe(
          map(news => addNewsImages.success({ news })),
          catchError(error => of(addNewsImages.error({ error })))
        )
      )
    )
  )

  deleteNewsImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteNewsImage.start),
      mergeMap(({ id, index }) =>
        this.newsService.deleteNewsImage(id, index).pipe(
          map(news => deleteNewsImage.success({ news })),
          catchError(error => of(deleteNewsImage.error({ error })))
        )
      )
    )
  )

  publishNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(publishNews.start),
      mergeMap(({ id, oldNewsItem }) =>
        this.newsService.publishNews(id, oldNewsItem).pipe(
          map(news => {
            this.notifyService.success('Новость опубликована')
            return publishNews.success({ news })
          }),
          catchError(error => of(publishNews.error({ error })))
        )
      )
    )
  )

  hideNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hideNews.start),
      mergeMap(({ id, oldNewsItem }) =>
        this.newsService.hideNews(id, oldNewsItem).pipe(
          map(news => {
            this.notifyService.success('Новость скрыта')
            return hideNews.success({ news })
          }),
          catchError(error => of(hideNews.error({ error })))
        )
      )
    )
  )

  getNewsItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getNewsItem.start),
      mergeMap(({ id }) =>
        this.newsService.getNewsItem(id).pipe(
          map(response => getNewsItem.success({ response })),
          catchError(error => of(getNewsItem.error({ error })))
        )
      )
    )
  )

  constructor(private actions$: Actions, private newsService: NewsService, private notifyService: NotifyService) {}
}
