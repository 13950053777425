import { Injectable } from '@angular/core'
import { RequestService } from '../../../../common/services/request.service'
import { Observable } from 'rxjs'
import { MinionFromMyTeamResponse } from '../../../../common/models/minion-from-my-team-response'
import { GetMinionMyTeamRequest } from '../../../../common/models/get-minion-my-team-request'
import { AllowWitdrawalRequest } from '../../../../common/models/allow-witdrawal-request'
import { SuccessResponse } from '../../../../common/models/success-response'
import { UpdateGroupOrderAccessRequest } from '../../../../common/models/update-group-order-access-request'
import { AllUserDataResp } from '../../../../common/models/all-user-data-resp'
import { IncreaseBalanceRequest } from '../../../../common/models/increase-balance-request'
import { UpdateUserAccessRequest } from '../../../../common/models/update-user-access-request'
import { BlockUserRequest } from 'common/models/block-user-request'
import { AdminPassRecoveryRequest } from 'common/models/admin-pass-recovery-request'
import { UpdateUserInfoRequest } from '../../../../common/models/update-user-info-request'
import { AccruePresidentialPoolRequest } from '../../../../common/models/accrue-presidential-pool-request'
import { AccrueGoldProGiftRequest } from '../../../../common/models/accrue-gold-pro-gift-request'
import { ShowInGradesRatingRequest } from '../../../../common/models/show-in-grades-rating-request'
import { ShowReceivedCarInGradesRatingRequest } from 'common/models/show-received-car-in-grades-rating-request'
import { MyTeamForAdminRequest } from 'common/models/my-team-for-admin-request'
import { MyTeamResponse } from 'common/models/my-team-response'
import { EnterGodMode } from '../../../../common/models/enter-god-mode'
import { AuthResponse } from '../../../../common/models/auth-response'
import { SwapMinionsRequest } from '../../../../common/models/swap-minions-request'
import { UserVisibleIdRequest } from '../../../../common/models/user-visible-id-request'
import { Family } from '../../../../common/models/family'
import { ApproveOrRejectRequest } from '../../../../common/models/approve-or-reject-request'
import { AllowWitdrawalThisMonthRequest } from 'common/models/allow-witdrawal-this-month-request'
import { UserIdRequest } from '../../../../common/models/user-id-request'
import { FundsTransferWithdrawalResponse } from 'common/models/funds-transfer-withdrawal-response'
import { CicTransferRequest } from 'common/models/cic-transfer-request'
import { StocksResponse } from 'common/models/stocks-response'
import { StockChangePermissionRequest } from 'common/models/stock-change-permission-request'

@Injectable({
  providedIn: 'root'
})
export class MinionService {
  load(req: MyTeamForAdminRequest): Observable<MyTeamResponse> {
    return this.request.post('/api/my_team/admin', req)
  }

  loadOne(req: GetMinionMyTeamRequest): Observable<MinionFromMyTeamResponse> {
    return this.request.post('/api/my_team/get_minion_v2', req)
  }

  allowWithdrawals(req: AllowWitdrawalRequest): Observable<SuccessResponse> {
    return this.request.post('/api/admin/withdrawals/allow', req)
  }

  updateWithdrawalLimit(req: AllowWitdrawalRequest): Observable<SuccessResponse> {
    return this.request.post('/api/admin/withdrawal_limit', req)
  }

  allowWithdrawalsThisMonth(req: AllowWitdrawalThisMonthRequest): Observable<SuccessResponse> {
    return this.request.post('/api/admin/withdrawals_this_month/allow', req)
  }

  updateGroupOrder(req: UpdateGroupOrderAccessRequest): Observable<AllUserDataResp> {
    return this.request.post('/api/admin/group_order/approve', req)
  }

  updateFakeDelivery(req: UpdateUserAccessRequest): Observable<AllUserDataResp> {
    return this.request.post('/api/admin/fake_delivery/approve', req)
  }

  updateShowReceivedCarInGradesRating(req: ShowReceivedCarInGradesRatingRequest): Observable<SuccessResponse> {
    return this.request.post('/api/admin/show_received_car_in_grades_rating', req)
  }

  updateShowInGradesRating(req: ShowInGradesRatingRequest): Observable<SuccessResponse> {
    return this.request.post('/api/admin/show_user_in_grades_rating', req)
  }

  increaseBalance(req: IncreaseBalanceRequest): Observable<AllUserDataResp> {
    return this.request.post('/api/admin/increase_balance', req)
  }

  blockUser(req: BlockUserRequest): Observable<AllUserDataResp> {
    return this.request.post('/api/admin/block_user', req)
  }

  deleteUser(req: UserIdRequest): Observable<AllUserDataResp> {
    return this.request.post('/api/admin/delete_user', req)
  }

  downgradeUser(req: UserIdRequest): Observable<AllUserDataResp> {
    return this.request.post('/api/admin/downgrade_user', req)
  }

  moveToCic(req: UserIdRequest): Observable<AllUserDataResp> {
    return this.request.post('/api/admin/cic_transfer', req)
  }

  passwordRecovery(req: AdminPassRecoveryRequest): Observable<SuccessResponse> {
    return this.request.post('/api/admin/password_recovery', req)
  }

  updateUserInfo(req: UpdateUserInfoRequest): Observable<AllUserDataResp> {
    return this.request.post('/api/admin/update_user_info', req)
  }

  accruePresidentialPool(req: AccruePresidentialPoolRequest): Observable<AllUserDataResp> {
    return this.request.post('/api/admin/accrue_presidential_pool', req)
  }

  accrueGoldProGift(req: AccrueGoldProGiftRequest): Observable<AllUserDataResp> {
    return this.request.post('/api/admin/accrue_gold_pro_gift', req)
  }

  godMode(req: EnterGodMode): Observable<AuthResponse> {
    return this.request.post('/api/admin/god_mode', req)
  }

  swap(req: SwapMinionsRequest): Observable<AllUserDataResp> {
    return this.request.post('/api/admin/minions/swap', req)
  }

  loadFamily(req: UserVisibleIdRequest): Observable<Family> {
    return this.request.post('/api/account/family_agreement/get', req)
  }

  updateStatus(req: ApproveOrRejectRequest): Observable<Family> {
    return this.request.post('/api/account/family_agreement/update_status', req)
  }

  withdrawInfo(req: UserVisibleIdRequest): Observable<FundsTransferWithdrawalResponse> {
    return this.request.post('/api/admin/funds_transfer/withdraw_info', req)
  }

  loadStocks(userId: number): Observable<StocksResponse> {
    return this.request.get<StocksResponse>('/api/admin/stock/without_self_pickup/' + userId)
  }

  tooglePermissionStock(req: StockChangePermissionRequest): Observable<SuccessResponse> {
    return this.request.post<StockChangePermissionRequest, SuccessResponse>(
      '/api/admin/stock/resolution_management',
      req
    )
  }

  constructor(private request: RequestService) {}
}
