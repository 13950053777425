import { createAction, createActionGroup, props } from '@ngrx/store'
import { GetMinionMyTeamRequest } from '../../../../../common/models/get-minion-my-team-request'
import { MinionFromMyTeamResponse } from '../../../../../common/models/minion-from-my-team-response'

export const minionIdLoad = createActionGroup({
  source: '[Minion Id] load',
  events: {
    start: props<{ req: GetMinionMyTeamRequest }>(),
    success: props<{ data: MinionFromMyTeamResponse }>(),
    error: props<{ error: string }>()
  }
})

export const minionIdClear = createAction('[Minion Id] clear')
