import { createActionGroup, props } from '@ngrx/store'
import { BonusesHistoryForAdminRequest } from '../../../../common/models/bonuses-history-for-admin-request'
import { BonusesHistory } from '../../../../common/models/bonuses-history'

export const bonusesHistoryLoad = createActionGroup({
  source: '[Bonuses history] load',
  events: {
    start: props<{ req: BonusesHistoryForAdminRequest }>(),
    success: props<{ data: BonusesHistory }>(),
    error: props<{ error: string }>()
  }
})

export const allErrors = [bonusesHistoryLoad.error]
