import { createReducer, on } from '@ngrx/store'
import { MinionFromMyTeamResponse } from '../../../../../common/models/minion-from-my-team-response'
import { minionIdLoad } from './minion-id.actions'

export const minionIdFeatureKey = 'minionId'

export interface State {
  data?: MinionFromMyTeamResponse
  isLoaded?: boolean
  isLoading?: boolean
  error?: string
}

export const initialState: State = {}

export const reducer = createReducer(
  initialState,
  on(minionIdLoad.start, state => ({ ...state, isLoading: true, isLoaded: false, error: undefined })),
  on(minionIdLoad.success, (state, { data }) => ({
    ...state,
    data,
    isLoading: false,
    isLoaded: true,
    error: undefined
  })),
  on(minionIdLoad.error, (state, { error }) => ({
    ...state,
    data: undefined,
    isLoading: false,
    isLoaded: false,
    error: error
  }))
)
