import { createReducer, on } from '@ngrx/store'
import { MinionFromMyTeamResponse } from '../../../../../common/models/minion-from-my-team-response'
import { minionSelectedClear, minionSelectedLoad, minionWithdrawInfo } from './minion-selected.actions'
import {
  minionGodMode,
  minionShowInGradesRatingUpdated,
  minionShowReceivedCarInGradesRatingUpdated,
  minionUpdated,
  minionWithdrawalsUpdated,
  minionWithdrawalsThisMonthUpdated,
  minionWithdrawalLimitUpdated
} from '../minion.actions'
import { AuthResponse } from '../../../../../common/models/auth-response'
import { minionFamilyUpdateStatus } from '../minion-family/minion-family.actions'
import { FundsTransferWithdrawalResponse } from 'common/models/funds-transfer-withdrawal-response'

export const minionSelectedFeatureKey = 'minionSelected'

export interface State {
  data?: MinionFromMyTeamResponse
  authResponse?: AuthResponse // for god mode
  isLoaded?: boolean
  error?: string
  withdrawInfo?: FundsTransferWithdrawalResponse
}

export const initialState: State = {}

export const reducer = createReducer(
  initialState,
  on(minionWithdrawInfo.start, state => ({
    ...state
    // isLoaded: true
  })),
  on(minionWithdrawInfo.success, (state, { data }) => ({
    ...state,
    // isLoaded: false,
    withdrawInfo: data
  })),
  on(minionWithdrawInfo.error, state => ({
    ...state,
    // isLoaded: false,
    withdrawInfo: undefined
  })),
  on(minionSelectedLoad.start, state => ({
    ...state,
    isLoading: true
  })),
  on(minionSelectedLoad.success, (state, { data }) => ({
    ...state,
    isLoaded: true,
    data,
    authResponse: undefined
  })),
  on(minionSelectedLoad.error, (state, { error }) => ({
    ...state,
    isLoaded: true,
    data: undefined,
    authResponse: undefined,
    error
  })),
  on(minionUpdated, (state, { data }) => ({
    ...state,
    data: {
      ...state.data,
      minion_data: data
    }
  })),
  on(minionWithdrawalsThisMonthUpdated, (state, { data }) => {
    if (state?.data?.minion_data?.main_user_data?.id !== data.user_id) return state
    return {
      ...state,
      data: {
        ...state.data,
        minion_data: {
          ...state.data.minion_data,
          main_user_data: {
            ...state.data.minion_data.main_user_data,
            withdrew_funds_this_month: data.allow
          }
        }
      }
    }
  }),
  on(minionWithdrawalsUpdated, (state, { data }) => {
    if (state?.data?.minion_data?.main_user_data?.id !== data.user_id) return state
    return {
      ...state,
      data: {
        ...state.data,
        minion_data: {
          ...state.data.minion_data,
          main_user_data: {
            ...state.data.minion_data.main_user_data,
            can_withdraw: data.allow
          }
        }
      }
    }
  }),
  on(minionWithdrawalLimitUpdated, (state, { data }) => {
    if (state?.data?.minion_data?.main_user_data?.id !== data.user_id) return state
    return {
      ...state,
      data: {
        ...state.data,
        minion_data: {
          ...state.data.minion_data,
          main_user_data: {
            ...state.data.minion_data.main_user_data,
            is_withdrawal_limit: data.allow
          }
        }
      }
    }
  }),
  on(minionShowInGradesRatingUpdated, (state, { data }) => {
    if (state?.data?.minion_data?.main_user_data?.id !== data.user_id) return state
    return {
      ...state,
      data: {
        ...state.data,
        minion_data: {
          ...state.data.minion_data,
          main_user_data: {
            ...state.data.minion_data.main_user_data,
            show_in_grades_rating: data.show
          }
        }
      }
    }
  }),
  on(minionShowReceivedCarInGradesRatingUpdated, (state, { data }) => {
    if (state?.data?.minion_data?.main_user_data?.id !== data.user_id) return state
    return {
      ...state,
      data: {
        ...state.data,
        minion_data: {
          ...state.data.minion_data,
          main_user_data: {
            ...state.data.minion_data.main_user_data,
            received_car: data.show
          }
        }
      }
    }
  }),
  on(minionGodMode.success, (state, { data }) => ({
    ...state,
    authResponse: data
  })),
  on(minionFamilyUpdateStatus.success, (state, { data }) => ({
    ...state,
    data: {
      ...state.data,
      minion_data: {
        ...state.data?.minion_data,
        family: data
      }
    }
  })),
  on(minionSelectedClear, () => initialState)
)
