import { createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { TicketMessage } from '../../../../../common/models/ticket-message'
import {
  ticketMessagesClear,
  ticketMessagesDelete,
  ticketMessagesLoad,
  ticketMessagesSendSuccess
} from './ticket-messages.actions'

export const ticketMessagesFeatureKey = 'ticketMessages'

export interface State extends EntityState<TicketMessage> {
  isLoading?: boolean
  isLoaded?: boolean
}

const selectId = (ticketMessage: TicketMessage) => ticketMessage.id ?? 0

const sortByTime = (a: TicketMessage, b: TicketMessage) => (a.sent_at ?? 0) - (b.sent_at ?? 0)

export const adapter = createEntityAdapter<TicketMessage>({
  selectId,
  sortComparer: sortByTime
})

export const initialState: State = adapter.getInitialState()

export const reducer = createReducer(
  initialState,
  on(ticketMessagesLoad.start, state => ({ ...state, isLoading: true })),
  on(ticketMessagesLoad.error, state => ({ ...state, isLoading: false, isLoaded: false })),
  on(ticketMessagesLoad.success, (state, { data }) => {
    const { messages } = data
    return adapter.setAll(messages || [], { ...state, isLoading: false, isLoaded: true })
  }),
  on(ticketMessagesSendSuccess, (state, { data }) => {
    if (!state.isLoaded || !data.message) return state
    return adapter.setOne(data.message, state)
  }),
  on(ticketMessagesDelete.success, (state, { data }) => {
    if (!state.isLoaded || !data.message?.id) return state
    return adapter.removeOne(data.message.id, state)
  }),
  on(ticketMessagesClear, () => initialState)
)
