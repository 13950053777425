import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Observable, catchError, map, mergeMap, of } from 'rxjs'
import { ReviewService } from '../review.service'
import { reviewListCreateError, reviewListCreateSuccess, reviewListLoad } from './review-list.actions'
import { CreateReviewRequest } from 'common/models/create-review-request'
import { Review } from 'common/models/review'
import { tapDispatch } from 'common/utils/store/actions'
import { Store } from '@ngrx/store'
import { AppState } from '../../state'

@Injectable()
export class ReviewListEffects {
  constructor(private actions$: Actions, private reviewService: ReviewService, private store: Store<AppState>) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reviewListLoad.start),
      mergeMap(({ req }) =>
        this.reviewService.load(req).pipe(
          map(data => reviewListLoad.success({ data })),
          catchError(error => of(reviewListLoad.error({ error })))
        )
      )
    )
  )

  create(data: CreateReviewRequest): Observable<Review> {
    return this.reviewService.create(data).pipe(
      tapDispatch(
        this.store,
        data => reviewListCreateSuccess({ data }),
        (error: string) => reviewListCreateError({ error })
      )
    )
  }
}
