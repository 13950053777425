import { Routes } from '@angular/router'
import { JwtGuard } from 'ngx-customapp-jwt'

export default [
  {
    path: 'auth',
    loadChildren: () => import('./auth/routes')
  },
  {
    path: 'main',
    loadChildren: () => import('./main/routes'),
    canActivate: [JwtGuard]
  },
  {
    path: '**',
    redirectTo: 'main'
  }
] as Routes
