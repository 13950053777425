import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'

import { catchError, map, mergeMap, of } from 'rxjs'
import { minionListLoad } from './minion-list.actions'
import { MinionService } from '../minion.service'

@Injectable()
export class MinionListEffects {
  constructor(private actions$: Actions, private minionService: MinionService) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(minionListLoad.start),
      mergeMap(({ req }) =>
        this.minionService.load(req).pipe(
          map(data => minionListLoad.success({ data })),
          catchError(error => of(minionListLoad.error({ error })))
        )
      )
    )
  )
}
