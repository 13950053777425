import { createAction, createActionGroup, props } from '@ngrx/store'
import { MinionFromMyTeamResponse } from '../../../../../common/models/minion-from-my-team-response'
import { GetMinionMyTeamRequest } from '../../../../../common/models/get-minion-my-team-request'
import { UserVisibleIdRequest } from 'common/models/user-visible-id-request'
import { FundsTransferWithdrawalResponse } from 'common/models/funds-transfer-withdrawal-response'

export const minionSelectedLoad = createActionGroup({
  source: '[Minion Selected] load',
  events: {
    start: props<{ req: GetMinionMyTeamRequest }>(),
    success: props<{ data: MinionFromMyTeamResponse }>(),
    error: props<{ error: string }>()
  }
})

export const minionWithdrawInfo = createActionGroup({
  source: '[Minion Withdraw] info',
  events: {
    start: props<{ req: UserVisibleIdRequest }>(),
    success: props<{ data: FundsTransferWithdrawalResponse }>(),
    error: props<{ error: string }>()
  }
})

export const minionSelectedClear = createAction('[Minion Selected] clear')
