import { Injectable } from '@angular/core'
import { RequestService } from '../../../../common/services/request.service'
import { Observable } from 'rxjs'
import { StocksResponse } from '../../../../common/models/stocks-response'
import { Stock } from '../../../../common/models/stock'
import { DeleteRequest } from '../../../../common/models/delete-request'
import { SuccessResponse } from 'common/models/success-response'
import { StockChangePermissionRequest } from 'common/models/stock-change-permission-request'

@Injectable({
  providedIn: 'root'
})
export class StockService {
  constructor(private request: RequestService) {}

  load(): Observable<StocksResponse> {
    return this.request.post<void, StocksResponse>('/api/stock/get', undefined)
  }

  add(stock: Stock): Observable<StocksResponse> {
    return this.request.post<Stock, StocksResponse>('/api/stock/add', stock)
  }

  update(stock: Stock): Observable<StocksResponse> {
    return this.request.post<Stock, StocksResponse>('/api/stock/update', stock)
  }

  delete(id: number): Observable<StocksResponse> {
    return this.request.post<DeleteRequest, StocksResponse>('/api/stock/delete', { id })
  }
}
