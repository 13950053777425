import { createAction, createActionGroup, props } from '@ngrx/store'
import { GetReviewsRequest } from 'common/models/get-reviews-request'
import { Review } from 'common/models/review'
import { ReviewsListResponse } from 'common/models/reviews-list-response'

export const reviewListLoad = createActionGroup({
  source: '[Reviews list] load',
  events: {
    start: props<{ req: GetReviewsRequest }>(),
    success: props<{ data: ReviewsListResponse }>(),
    error: props<{ error: string }>()
  }
})

export const reviewListClear = createAction('[Review list] clear')

export const reviewListCreateSuccess = createAction('[Review list] create success', props<{ data: Review }>())

export const reviewListCreateError = createAction('[Review list] create error', props<{ error: string }>())

export const allErrors = [reviewListLoad.error, reviewListCreateError]
