import { createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { ProductWithItems } from '../../../../common/models/product-with-items'
import {
  productListAddSuccess,
  productListCertificateAddSuccess,
  productListCertificateDeleteSuccess,
  productListCertificateUpdateSuccess,
  productListDeleteSuccess,
  productListLoadSuccess,
  productListSliderImageDeleteSuccess,
  productListSliderImageUpdateSuccess,
  productListUpdateStart,
  productListUpdateSuccess
} from './product-list.actions'

export const productListFeatureKey = 'productList'

export interface State extends EntityState<ProductWithItems> {
  isLoaded: boolean
}

function selectId(product: ProductWithItems): number {
  return product.id ?? 0
}

function sortByPos(a: ProductWithItems, b: ProductWithItems): number {
  return (a.position ?? 0) - (b.position ?? 0)
}

export const adapter = createEntityAdapter({
  selectId,
  sortComparer: sortByPos
})

export const initialState: State = adapter.getInitialState({
  isLoaded: false
})

export const reducer = createReducer(
  initialState,
  on(productListUpdateStart, (state, { product }) => {
    if (!product.id || !state.isLoaded) {
      return state
    }
    const { selectAll, selectEntities } = adapter.getSelectors()
    const originalProduct = selectEntities(state)[product.id]
    if (!originalProduct) {
      return state
    }
    const originalPos = originalProduct.position ?? 0
    const targetPos = product.position ?? 0
    if (originalPos === targetPos) {
      return state
    }
    let start: number
    let end: number
    let posDelta: number
    if (originalPos < targetPos) {
      start = originalPos + 1
      end = targetPos
      posDelta = -1
    } else {
      start = targetPos
      end = originalPos - 1
      posDelta = 1
    }
    const all = selectAll(state)
    const updatedAll = all.map(p => {
      if (p.id === product.id) {
        return {
          ...p,
          position: product.position
        }
      }
      const pos = p.position ?? 0
      if (pos >= start && pos <= end) {
        return {
          ...p,
          position: pos + posDelta
        }
      }
      return p
    })
    return adapter.setAll(updatedAll, state)
  }),
  on(
    productListLoadSuccess,
    productListAddSuccess,
    productListUpdateSuccess,
    productListDeleteSuccess,
    (state, { data }) => adapter.setAll(data, { ...state, isLoaded: true })
  ),
  on(
    productListCertificateAddSuccess,
    productListCertificateUpdateSuccess,
    productListCertificateDeleteSuccess,
    productListSliderImageUpdateSuccess,
    productListSliderImageDeleteSuccess,
    (state, { data }) => {
      if (state.isLoaded) {
        return adapter.setOne(data, state)
      }
      return state
    }
  )
)
