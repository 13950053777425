import { Injectable } from '@angular/core'
import { Actions } from '@ngrx/effects'
import { mergeMap, Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import {
  itemListAddError,
  itemListAddSuccess,
  itemListImageAddError,
  itemListImageAddSuccess,
  itemListImageDeleteError,
  itemListImageDeleteSuccess,
  itemListImageUpdateError,
  itemListImageUpdateSuccess,
  itemListLoadError,
  itemListLoadSuccess,
  itemListUpdateError,
  itemListUpdateSuccess
} from './item-list.actions'
import { ItemListService } from './item-list.service'
import { AppState } from '../state'
import { Item } from '../../../../common/models/item'
import { tapDispatch } from '../../../../common/utils/store/actions'
import { ItemToAdd } from '../../../../common/models/item-to-add'
import { UpdateItemImageRequest } from '../../../../common/models/update-item-image-request'
import { DeleteItemImageRequest } from '../../../../common/models/delete-item-image-request'
import { fileListToB64 } from '../../../../common/utils/files/file-to-b64'

@Injectable()
export class ItemListEffects {
  constructor(private actions$: Actions, private itemListService: ItemListService, private store: Store<AppState>) {}

  load(): Observable<Item[]> {
    return this.itemListService.load().pipe(
      tapDispatch(
        this.store,
        data => itemListLoadSuccess({ data }),
        (error: string) => itemListLoadError({ error })
      )
    )
  }

  add(itemToAdd: ItemToAdd): Observable<Item> {
    return this.itemListService.add(itemToAdd).pipe(
      tapDispatch(
        this.store,
        data => itemListAddSuccess({ data }),
        (error: string) => itemListAddError({ error })
      )
    )
  }

  update(item: Item): Observable<Item> {
    return this.itemListService.update(item).pipe(
      tapDispatch(
        this.store,
        data => itemListUpdateSuccess({ data }),
        (error: string) => itemListUpdateError({ error })
      )
    )
  }

  imageAdd(itemId: number, images: File[]): Observable<Item> {
    return fileListToB64(images).pipe(
      mergeMap(b64Images =>
        this.itemListService.imageAdd({
          item_id: itemId,
          images: b64Images.map(image => ({
            image: image.b64,
            alt: image.name
          }))
        })
      ),
      tapDispatch(
        this.store,
        data => itemListImageAddSuccess({ data }),
        (error: string) => itemListImageAddError({ error })
      )
    )
  }

  imageUpdate(req: UpdateItemImageRequest): Observable<Item> {
    return this.itemListService.imageUpdate(req).pipe(
      tapDispatch(
        this.store,
        data => itemListImageUpdateSuccess({ data }),
        (error: string) => itemListImageUpdateError({ error })
      )
    )
  }

  imageDelete(req: DeleteItemImageRequest): Observable<Item> {
    return this.itemListService.imageDelete(req).pipe(
      tapDispatch(
        this.store,
        data => itemListImageDeleteSuccess({ data }),
        (error: string) => itemListImageDeleteError({ error })
      )
    )
  }
}
