import { Injectable } from '@angular/core'
import { ErrorsService } from 'ngx-customapp-errors'
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http'
import { catchError, Observable } from 'rxjs'
import { apiBaseUrl, platformOs, appVersion } from '../config/http'

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  constructor(private errorsService: ErrorsService, private http: HttpClient) {}

  get<Response>(
    path: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[]
          }
      context?: HttpContext
      params?:
        | HttpParams
        | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>
          }
      reportProgress?: boolean
      withCredentials?: boolean
    }
  ): Observable<Response> {
    const headers =
      options?.headers instanceof HttpHeaders
        ? options.headers.set('X-Platform-OS', platformOs).set('X-App-Version', appVersion)
        : {
            ...options?.headers,
            'X-App-Version': appVersion,
            'X-Platform-OS': platformOs
          }

    return this.http
      .get<Response>(`${apiBaseUrl}${path}`, {
        ...options,
        headers,
        observe: 'body',
        responseType: 'json'
      })
      .pipe(
        catchError(err => {
          throw err
        }),
        catchError(this.errorsService.reportError),
        catchError(this.errorsService.toUserError)
      )
  }

  delete<RequestBody, Response>(
    path: string,
    body: RequestBody,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[]
          }
      context?: HttpContext
      params?:
        | HttpParams
        | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>
          }
      reportProgress?: boolean
      withCredentials?: boolean
    }
  ): Observable<Response> {
    const headers =
      options?.headers instanceof HttpHeaders
        ? options.headers.set('X-Platform-OS', platformOs).set('X-App-Version', appVersion)
        : {
            ...options?.headers,
            'X-App-Version': appVersion,
            'X-Platform-OS': platformOs
          }

    return this.http
      .request<Response>('DELETE', `${apiBaseUrl}${path}`, {
        ...options,
        headers,
        body: body,
        observe: 'body',
        responseType: 'json'
      })
      .pipe(
        catchError(err => {
          console.log('error', err)
          throw err
        }),
        catchError(this.errorsService.reportError),
        catchError(this.errorsService.toUserError)
      )
  }

  post<RequestParameters, Response>(
    path: string,
    request: RequestParameters,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[]
          }
      context?: HttpContext
      params?:
        | HttpParams
        | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>
          }
      reportProgress?: boolean
      withCredentials?: boolean
    }
  ): Observable<Response> {
    const headers =
      options?.headers instanceof HttpHeaders
        ? options.headers.set('X-Platform-OS', platformOs).set('X-App-Version', appVersion)
        : {
            ...options?.headers,
            'X-App-Version': appVersion,
            'X-Platform-OS': platformOs
          }

    return this.http
      .post<Response>(apiBaseUrl + path, request, {
        ...options,
        headers,
        observe: 'body',
        responseType: 'json'
      })
      .pipe(
        catchError(err => {
          console.log('error', err)
          throw err
        }),
        catchError(this.errorsService.reportError),
        catchError(this.errorsService.toUserError)
      )
  }

  put<RequestParameters, Response>(
    path: string,
    request: RequestParameters,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[]
          }
      context?: HttpContext
      params?:
        | HttpParams
        | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>
          }
      reportProgress?: boolean
      withCredentials?: boolean
    }
  ): Observable<Response> {
    const headers =
      options?.headers instanceof HttpHeaders
        ? options.headers.set('X-Platform-OS', platformOs).set('X-App-Version', appVersion)
        : {
            ...options?.headers,
            'X-App-Version': appVersion,
            'X-Platform-OS': platformOs
          }

    return this.http
      .put<Response>(apiBaseUrl + path, request, {
        ...options,
        headers,
        observe: 'body',
        responseType: 'json'
      })
      .pipe(
        catchError(err => {
          console.log('error', err)
          throw err
        }),
        catchError(this.errorsService.reportError),
        catchError(this.errorsService.toUserError)
      )
  }
}
