/**
 * Один и тот же стор используется как для работы со всем списком заявок, так и с отдельно взятой
 */
import { createAction, createActionGroup, props } from '@ngrx/store'
import { GetTicketsRequest } from '../../../../../common/models/get-tickets-request'
import { TicketsListResponse } from '../../../../../common/models/tickets-list-response'
import { errorProps } from '../../../../../common/utils/store/types'
import { Ticket } from '../../../../../common/models/ticket'
import { DeleteTicketsRequestInner } from '../../../../../common/models/delete-tickets-request-inner'
import { GetTicketByIdResponse } from '../../../../../common/models/get-ticket-by-id-response'

export const ticketListLoad = createActionGroup({
  source: '[TicketList] load',
  events: {
    start: props<{ req: GetTicketsRequest }>(),
    success: props<{ data: TicketsListResponse }>(),
    error: errorProps
  }
})

export const ticketListLoadOne = createActionGroup({
  source: '[TicketList] load one',
  events: {
    start: props<{ id: number }>(),
    success: props<{ data: GetTicketByIdResponse }>(),
    error: errorProps
  }
})

export const ticketListUpdateSuccess = createAction('[TicketList] update success', props<{ data: Ticket }>())

export const ticketListUpdateError = createAction('[TicketList] update error', errorProps)

export const ticketListDeleteSuccess = createAction(
  '[TicketList] delete success',
  props<{ data: DeleteTicketsRequestInner }>()
)

export const ticketListDeleteError = createAction('[TicketList] delete error', errorProps)

export const ticketListSeizeSuccess = createAction('[TicketList] seize', props<{ data: Ticket }>())

export const ticketListSeizeError = createAction('[TicketList] seize error', errorProps)

export const ticketListClear = createAction('[TicketList] clear')

export const allErrors = [ticketListLoad.error, ticketListLoadOne.error, ticketListUpdateError, ticketListDeleteError]
