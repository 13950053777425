import { AllUserDataResp } from '../../../../common/models/all-user-data-resp'
import { createAction, createActionGroup, props } from '@ngrx/store'
import { AllowWitdrawalRequest } from '../../../../common/models/allow-witdrawal-request'
import { AllowWitdrawalThisMonthRequest } from '../../../../common/models/allow-witdrawal-this-month-request'
import { ShowInGradesRatingRequest } from '../../../../common/models/show-in-grades-rating-request'
import { ShowReceivedCarInGradesRatingRequest } from 'common/models/show-received-car-in-grades-rating-request'
import { errorProps } from '../../../../common/utils/store/types'
import { AuthResponse } from '../../../../common/models/auth-response'

export const minionUpdated = createAction('[Minion] updated', props<{ data: AllUserDataResp }>())

export const minionWithdrawalsUpdated = createAction(
  '[Minion] withdrawals allowed',
  props<{ data: AllowWitdrawalRequest }>()
)

export const minionWithdrawalLimitUpdated = createAction(
  '[Minion] withdrawals limit',
  props<{ data: AllowWitdrawalRequest }>()
)

export const minionWithdrawalsThisMonthUpdated = createAction(
  '[Minion] withdrawals this month allowed',
  props<{ data: AllowWitdrawalThisMonthRequest }>()
)

export const minionShowReceivedCarInGradesRatingUpdated = createAction(
  '[Minion] show received car in grades rating updated',
  props<{ data: ShowReceivedCarInGradesRatingRequest }>()
)

export const minionShowInGradesRatingUpdated = createAction(
  '[Minion] show in grades rating updated',
  props<{ data: ShowInGradesRatingRequest }>()
)

export const minionUpdateError = createAction('[Minion] update error', props<{ error: string }>())
export const minionPasswordRecovery = createAction('[Minion] password recovery')

export const minionGodMode = createActionGroup({
  source: '[Minion] god mode',
  events: {
    success: props<{ data: AuthResponse }>(),
    error: errorProps
  }
})

export const minionSwap = createActionGroup({
  source: '[Minion] swap',
  events: {
    success: props<{ data: AllUserDataResp }>(),
    error: errorProps
  }
})

export const allErrors = [minionUpdateError, minionGodMode.error, minionSwap.error]
