import { createReducer, on } from '@ngrx/store'
import { EntityState, createEntityAdapter } from '@ngrx/entity'
import { Navigation } from 'common/models/navigation'
import { reviewListClear, reviewListLoad } from './review-list.actions'
import { Review } from 'common/models/review'

export const reviewListFeatureKey = 'reviewList'

export interface State extends EntityState<Review> {
  navigation?: Navigation
  isLoading?: boolean
  isLoaded?: boolean
}

const selectId = ({ id }: Review) => id ?? 0

export const adapter = createEntityAdapter<Review>({ selectId })

export const initialState: State = adapter.getInitialState()

export const reducer = createReducer(
  initialState,
  on(reviewListLoad.start, state => ({ ...state, isLoading: true })),
  on(reviewListLoad.error, state => ({ ...state, isLoading: false, isLoaded: false })),
  on(reviewListLoad.success, (state, { data }) => {
    const { reviews, navigation } = data

    return adapter.setAll(reviews || [], { ...state, navigation, isLoading: false, isLoaded: true })
  }),
  on(reviewListClear, () => initialState)
)
