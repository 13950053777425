import { createReducer, on } from '@ngrx/store'
import { Family } from '../../../../../common/models/family'
import { minionFamilyClear, minionFamilyLoad, minionFamilyUpdateStatus } from './minion-family.actions'

export const minionFamilyFeatureKey = 'minionFamily'

export interface State {
  data?: Family
}

export const initialState: State = {}

export const reducer = createReducer(
  initialState,
  on(minionFamilyLoad.success, (_, { data }) => ({
    data
  })),
  on(minionFamilyUpdateStatus.success, (_, { data }) => ({
    data
  })),
  on(minionFamilyClear, () => initialState)
)
