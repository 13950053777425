import { ActionReducerMap } from '@ngrx/store'
import { CommonState } from 'common/store/state'
import * as fromItemList from './item-list/item-list.reducer'
import * as fromProductList from './product-list/product-list.reducer'
import * as fromOrders from 'common/store/orders/orders.reducer'
import * as fromOrderSelected from './order/order-selected/order-selected.reducer'
import * as fromStock from './stock/stock.reducer'
import * as fromWithdrawal from './withdrawal/withdrawal.reducer'
import * as fromMinionSelected from './minion/minion-selected/minion-selected.reducer'
import * as fromConfig from './config/config.reducer'
import * as fromTicket from './ticket/ticket-list/ticket-list.reducer'
import * as fromTicketMessages from './ticket/ticket-messages/ticket-messages.reducer'
import * as fromBonusesHistory from './bonuses-history/bonuses-history.reducer'
import * as fromTransfersHistory from './transfers-history/transfers-history.reducer'
import * as fromMinionList from './minion/minion-list/minion-list.reducer'
import * as fromReviewList from './review/review-list/review-list.reducer'
import * as fromReviewSelected from './review/review-selected/review-selected.reducer'
import * as fromStat from './stat/stat.reducer'
import * as fromNews from './news/news.reducer'
import * as fromEvents from './events/event.reducer'
import * as fromMinionId from './minion/minion-id/minion-id.reducer'
import * as fromGeoSearch from '../../../common/store/geo-search/geo-search.reducer'
import * as fromMinionFamily from './minion/minion-family/minion-family.reducer'
import * as fromSystemData from './system-data/system-data.reducer'
import * as fromMinionStocks from './minion/minion-stocks/minion-stocks.reducer'

interface State {
  [fromItemList.itemListFeatureKey]: fromItemList.State
  [fromProductList.productListFeatureKey]: fromProductList.State
  [fromOrders.ordersFeatureKey]: fromOrders.State
  [fromOrderSelected.orderSelectedFeatureKey]: fromOrderSelected.State
  [fromStock.stockFeatureKey]: fromStock.State
  [fromGeoSearch.featureKey]: fromGeoSearch.State
  [fromWithdrawal.withdrawalFeatureKey]: fromWithdrawal.State
  [fromMinionSelected.minionSelectedFeatureKey]: fromMinionSelected.State
  [fromConfig.configFeatureKey]: fromConfig.State
  [fromTicket.ticketListFeatureKey]: fromTicket.State
  [fromTicketMessages.ticketMessagesFeatureKey]: fromTicketMessages.State
  [fromBonusesHistory.bonusesHistoryFeatureKey]: fromBonusesHistory.State
  [fromTransfersHistory.transfersHistoryFeatureKey]: fromTransfersHistory.State
  [fromMinionList.minionListFeatureKey]: fromMinionList.State
  [fromReviewList.reviewListFeatureKey]: fromReviewList.State
  [fromReviewSelected.reviewSelectedFeatureKey]: fromReviewSelected.State
  [fromStat.statFeatureKey]: fromStat.State
  [fromNews.featureKey]: fromNews.State
  [fromEvents.featureKey]: fromEvents.State
  [fromMinionId.minionIdFeatureKey]: fromMinionId.State
  [fromMinionFamily.minionFamilyFeatureKey]: fromMinionFamily.State
  [fromMinionStocks.minionStocksFeatureKey]: fromMinionStocks.State
  [fromSystemData.featureKey]: fromSystemData.State
}

export interface AppState extends CommonState, State {}

export const reducers: ActionReducerMap<State> = {
  [fromItemList.itemListFeatureKey]: fromItemList.reducer,
  [fromProductList.productListFeatureKey]: fromProductList.reducer,
  [fromOrders.ordersFeatureKey]: fromOrders.reducer,
  [fromOrderSelected.orderSelectedFeatureKey]: fromOrderSelected.reducer,
  [fromStock.stockFeatureKey]: fromStock.reducer,
  [fromGeoSearch.featureKey]: fromGeoSearch.reducer,
  [fromWithdrawal.withdrawalFeatureKey]: fromWithdrawal.reducer,
  [fromMinionSelected.minionSelectedFeatureKey]: fromMinionSelected.reducer,
  [fromConfig.configFeatureKey]: fromConfig.reducer,
  [fromTicket.ticketListFeatureKey]: fromTicket.reducer,
  [fromTicketMessages.ticketMessagesFeatureKey]: fromTicketMessages.reducer,
  [fromBonusesHistory.bonusesHistoryFeatureKey]: fromBonusesHistory.reducer,
  [fromTransfersHistory.transfersHistoryFeatureKey]: fromTransfersHistory.reducer,
  [fromMinionList.minionListFeatureKey]: fromMinionList.reducer,
  [fromReviewList.reviewListFeatureKey]: fromReviewList.reducer,
  [fromReviewSelected.reviewSelectedFeatureKey]: fromReviewSelected.reducer,
  [fromStat.statFeatureKey]: fromStat.reducer,
  [fromNews.featureKey]: fromNews.reducer,
  [fromEvents.featureKey]: fromEvents.reducer,
  [fromMinionId.minionIdFeatureKey]: fromMinionId.reducer,
  [fromMinionFamily.minionFamilyFeatureKey]: fromMinionFamily.reducer,
  [fromSystemData.featureKey]: fromSystemData.reducer,
  [fromMinionStocks.minionStocksFeatureKey]: fromMinionStocks.reducer
}
