import { Config } from '../../../../common/models/config'
import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { errorProps } from '../../../../common/utils/store/types'

export const configLoad = createActionGroup({
  source: '[Config] load',
  events: {
    start: emptyProps(),
    success: props<{ data: Config }>(),
    error: errorProps
  }
})

export const allErrors = [configLoad.error]
