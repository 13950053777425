import { createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import {
  itemListAddSuccess,
  itemListImageAddSuccess,
  itemListImageDeleteSuccess,
  itemListImageUpdateSuccess,
  itemListLoadError,
  itemListLoadSuccess,
  itemListRemove,
  itemListUpdateSuccess
} from './item-list.actions'
import { Item } from '../../../../common/models/item'

export const itemListFeatureKey = 'itemList'

export interface State extends EntityState<Item> {
  isLoaded: boolean
}

function selectId(item: Item): number {
  return item.id!
}

function sortById(a: Item, b: Item): number {
  return a.id! - b.id!
}

export const adapter = createEntityAdapter<Item>({
  selectId,
  sortComparer: sortById
})

const initialState: State = adapter.getInitialState({
  isLoaded: false
})

export const reducer = createReducer(
  initialState,
  on(itemListLoadSuccess, (state, { data }) => adapter.setAll(data, { ...state, isLoaded: true })),
  on(itemListLoadError, itemListRemove, () => initialState),
  on(
    itemListAddSuccess,
    itemListUpdateSuccess,
    itemListImageAddSuccess,
    itemListImageUpdateSuccess,
    itemListImageDeleteSuccess,
    (state, { data }) => {
      if (state.isLoaded) {
        return adapter.setOne(data, state)
      }
      return state
    }
  )
)
