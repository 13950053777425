import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'

import { catchError, map, mergeMap, of } from 'rxjs'
import { TransfersHistoryService } from './transfers-history.service'
import { revertTransfer, transfersHistoryLoad } from './transfers-history.actions'
import { NotifyService } from 'admin/src/app/services/notify.service'

@Injectable()
export class TransfersHistoryEffects {
  constructor(
    private actions$: Actions,
    private transfersHistoryService: TransfersHistoryService,
    private notifyService: NotifyService
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transfersHistoryLoad.start),
      mergeMap(({ req }) =>
        this.transfersHistoryService.load(req).pipe(
          map(data => transfersHistoryLoad.success({ data })),
          catchError(error => of(transfersHistoryLoad.error({ error })))
        )
      )
    )
  )

  revertTransfer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(revertTransfer.start),
      mergeMap(({ reason, transferId }) =>
        this.transfersHistoryService.revertTransfer(transferId, reason).pipe(
          map(transfer => {
            this.notifyService.success('Перевод отменен')
            return revertTransfer.success({ transfer })
          }),
          catchError(error => of(revertTransfer.error({ error })))
        )
      )
    )
  )
}
