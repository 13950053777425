import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { BonusesHistory } from '../../../../common/models/bonuses-history'
import { RequestService } from '../../../../common/services/request.service'
import { BonusesHistoryForAdminRequest } from '../../../../common/models/bonuses-history-for-admin-request'

@Injectable({
  providedIn: 'root'
})
export class BonusesHistoryService {
  constructor(private request: RequestService) {}

  load(req: BonusesHistoryForAdminRequest): Observable<BonusesHistory> {
    return this.request.post('/api/account/history/admin', req)
  }
}
