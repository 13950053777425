import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import {
  addEventImages,
  createDraftEventItem,
  deleteEventImage,
  deleteEventItem,
  getEventItem,
  getEventList,
  hideEvent,
  publishEvent,
  updateEventItem
} from './event.actions'
import { EventService } from './event.service'
import { NotifyService } from 'admin/src/app/services/notify.service'

@Injectable()
export class EventEffects {
  getEventList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEventList.start),
      mergeMap(({ page }) =>
        this.eventService.getEventList(page).pipe(
          map(response => getEventList.success({ response })),
          catchError(error => of(getEventList.error({ error })))
        )
      )
    )
  )

  createDraftEventItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createDraftEventItem.start),
      mergeMap(({ event }) =>
        this.eventService.createDraftEventItem(event).pipe(
          map(event => createDraftEventItem.success({ event })),
          catchError(error => of(createDraftEventItem.error({ error })))
        )
      )
    )
  )

  updateEventItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEventItem.start),
      mergeMap(({ data, oldEventItem }) =>
        this.eventService.updateEventItem(data, oldEventItem).pipe(
          map(event => updateEventItem.success({ event })),
          catchError(error => of(updateEventItem.error({ error })))
        )
      )
    )
  )

  deleteEventItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteEventItem.start),
      mergeMap(({ id }) =>
        this.eventService.deleteEventItem(id).pipe(
          map(() => {
            this.notifyService.success('Событие удалено')
            return deleteEventItem.success({ id })
          }),
          catchError(error => of(deleteEventItem.error({ error })))
        )
      )
    )
  )

  addEventImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addEventImages.start),
      mergeMap(({ id, images }) =>
        this.eventService.addEventImages(id, images).pipe(
          map(event => addEventImages.success({ event })),
          catchError(error => of(addEventImages.error({ error })))
        )
      )
    )
  )

  deleteEventImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteEventImage.start),
      mergeMap(({ id, index }) =>
        this.eventService.deleteEventImage(id, index).pipe(
          map(event => deleteEventImage.success({ event })),
          catchError(error => of(deleteEventImage.error({ error })))
        )
      )
    )
  )

  publishEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(publishEvent.start),
      mergeMap(({ id, oldEventItem }) =>
        this.eventService.publishEvent(id, oldEventItem).pipe(
          map(event => {
            this.notifyService.success('Событие опубликовано')
            return publishEvent.success({ event })
          }),
          catchError(error => of(publishEvent.error({ error })))
        )
      )
    )
  )

  hideEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hideEvent.start),
      mergeMap(({ id, oldEventItem }) =>
        this.eventService.hideEvent(id, oldEventItem).pipe(
          map(event => {
            this.notifyService.success('Событие скрыто')
            return hideEvent.success({ event })
          }),
          catchError(error => of(hideEvent.error({ error })))
        )
      )
    )
  )

  getEventItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEventItem.start),
      mergeMap(({ id }) =>
        this.eventService.getEventItem(id).pipe(
          map(event => getEventItem.success({ event })),
          catchError(error => of(getEventItem.error({ error })))
        )
      )
    )
  )

  constructor(private actions$: Actions, private eventService: EventService, private notifyService: NotifyService) {}
}
