import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  ticketListDeleteError,
  ticketListDeleteSuccess,
  ticketListLoad,
  ticketListLoadOne,
  ticketListSeizeError,
  ticketListSeizeSuccess,
  ticketListUpdateError,
  ticketListUpdateSuccess
} from './ticket-list.actions'
import { TicketService } from '../ticket.service'
import { catchError, map, mergeMap, Observable, of } from 'rxjs'
import { Ticket } from '../../../../../common/models/ticket'
import { tapDispatch } from '../../../../../common/utils/store/actions'
import { AppState } from '../../state'
import { Store } from '@ngrx/store'
import { SuccessResponse } from '../../../../../common/models/success-response'

@Injectable()
export class TicketListEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ticketListLoad.start),
      mergeMap(({ req }) =>
        this.ticketService.load(req).pipe(
          map(data => ticketListLoad.success({ data })),
          catchError(error => of(ticketListLoad.error({ error })))
        )
      )
    )
  )

  loadOne$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ticketListLoadOne.start),
      mergeMap(({ id }) =>
        this.ticketService.loadOne(id).pipe(
          map(data => ticketListLoadOne.success({ data })),
          catchError(error => of(ticketListLoadOne.error({ error })))
        )
      )
    )
  )

  update(ticket: Ticket): Observable<Ticket> {
    return this.ticketService.update([ticket]).pipe(
      map(data => data[0]),
      tapDispatch(
        this.store,
        data => ticketListUpdateSuccess({ data }),
        (error: string) => ticketListUpdateError({ error })
      )
    )
  }

  delete(ticketId: number): Observable<SuccessResponse> {
    return this.ticketService.delete([{ ticket_id: ticketId }]).pipe(
      tapDispatch(
        this.store,
        () => ticketListDeleteSuccess({ data: { ticket_id: ticketId } }),
        (error: string) => ticketListDeleteError({ error })
      )
    )
  }

  seize(ticketId: number): Observable<Ticket> {
    return this.ticketService.seize(ticketId).pipe(
      tapDispatch(
        this.store,
        data => ticketListSeizeSuccess({ data }),
        (error: string) => ticketListSeizeError({ error })
      )
    )
  }

  constructor(private actions$: Actions, private ticketService: TicketService, private store: Store<AppState>) {}
}
