import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { RequestService } from 'common/services/request.service'
import { GetReviewsRequest } from 'common/models/get-reviews-request'
import { ReviewsListResponse } from 'common/models/reviews-list-response'
import { Review } from 'common/models/review'
import { DeleteReviewsRequestInner } from 'common/models/delete-reviews-request-inner'
import { SuccessResponse } from 'common/models/success-response'
import { CreateReviewRequest } from 'common/models/create-review-request'

@Injectable({
  providedIn: 'root'
})
export class ReviewService {
  constructor(private request: RequestService) {}

  load(req: GetReviewsRequest): Observable<ReviewsListResponse> {
    return this.request.post('/api/reviews', req)
  }

  loadOne(reviewId: string): Observable<Review> {
    return this.request.post(`/api/reviews/${reviewId}`, undefined)
  }

  update(req: Review): Observable<Review[]> {
    return this.request.post<Review[], Review[]>('/api/reviews/update', [req])
  }

  delete(req: DeleteReviewsRequestInner): Observable<SuccessResponse> {
    return this.request.post('/api/reviews/delete', [req])
  }

  create(req: CreateReviewRequest): Observable<Review> {
    return this.request.post('/api/reviews/create', req)
  }
}
