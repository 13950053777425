import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { MinionFromMyTeamResponse } from '../../../../../common/models/minion-from-my-team-response'
import { MinionService } from '../minion.service'
import { minionSelectedLoad, minionWithdrawInfo } from './minion-selected.actions'
import { FundsTransferWithdrawalResponse } from 'common/models/funds-transfer-withdrawal-response'

@Injectable()
export class MinionSelectedEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(minionSelectedLoad.start),
      mergeMap(({ req }) =>
        this.minionService.loadOne(req).pipe(
          map((data: MinionFromMyTeamResponse) => minionSelectedLoad.success({ data })),
          catchError((error: string) => of(minionSelectedLoad.error({ error })))
        )
      )
    )
  )

  withdrawInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(minionWithdrawInfo.start),
      mergeMap(({ req }) =>
        this.minionService.withdrawInfo(req).pipe(
          map((data: FundsTransferWithdrawalResponse) => minionWithdrawInfo.success({ data })),
          catchError((error: string) => of(minionWithdrawInfo.error({ error })))
        )
      )
    )
  )

  constructor(private actions$: Actions, private minionService: MinionService) {}
}
