import { createAction, createActionGroup, props } from '@ngrx/store'
import { errorProps } from '../../../../../common/utils/store/types'
import { TicketMessagesResponse } from '../../../../../common/models/ticket-messages-response'
import { TicketWithMessage } from '../../../../../common/models/ticket-with-message'

export const ticketMessagesLoad = createActionGroup({
  source: '[TicketMessages] load',
  events: {
    start: props<{ ticketId: number }>(),
    success: props<{ data: TicketMessagesResponse }>(),
    error: errorProps
  }
})
export const ticketMessagesSendSuccess = createAction(
  '[TicketMessages] send success',
  props<{ data: TicketWithMessage }>()
)

export const ticketMessagesSendError = createAction('[TicketMessages] send error', errorProps)

export const ticketMessagesDelete = createActionGroup({
  source: '[TicketMessages] delete',
  events: {
    success: props<{ data: TicketWithMessage }>(),
    error: errorProps
  }
})

export const ticketMessagesClear = createAction('[TicketMessages] clear')

export const allErrors = [ticketMessagesLoad.error, ticketMessagesSendError, ticketMessagesDelete.error]
