import { Injectable } from '@angular/core'
import { AddNewsImagesRequest } from 'common/models/add-news-images-request'
import { CreateNewsRequest } from 'common/models/create-news-request'
import { DeleteNewsImageRequest } from 'common/models/delete-news-image-request'
import { DeleteNewsRequest } from 'common/models/delete-news-request'
import { News } from 'common/models/news'
import { NewsListResponse } from 'common/models/news-list-response'
import { UpdateNewsRequest } from 'common/models/update-news-request'
import { RequestService } from 'common/services/request.service'
import { fileListToB64 } from 'common/utils/files/file-to-b64'
import { mergeMap } from 'rxjs'
import { GetNewsByIdResponse } from 'common/models/get-news-by-id-response'

export const NEWS_PAGE_SIZE = 50

@Injectable({ providedIn: 'root' })
export class NewsService {
  constructor(private requestService: RequestService) {}

  getNewsList(page: number) {
    return this.requestService.post<unknown, NewsListResponse>(
      '/api/news',
      {},
      { params: { page, page_size: NEWS_PAGE_SIZE } }
    )
  }

  getNewsItem(id: number) {
    return this.requestService.post<unknown, GetNewsByIdResponse>(`/api/news/${id}`, {})
  }

  createDraftNewsItem({ content, title, push_text }: Pick<CreateNewsRequest, 'title' | 'content' | 'push_text'>) {
    return this.requestService.post<CreateNewsRequest, News>('/api/news/create', {
      title,
      content,
      publish: false,
      images: [],
      push_text: push_text
    })
  }

  updateNewsItem(data: Omit<UpdateNewsRequest, 'publish'>, oldNewsItem: News) {
    return this.requestService.post<UpdateNewsRequest, News>('/api/news/update', {
      ...data,
      publish: oldNewsItem.published ?? false
    })
  }

  deleteNewsItem(id: number) {
    return this.requestService.post<DeleteNewsRequest, void>('/api/news/delete', { news_id: id })
  }

  addNewsImages(id: number, images: File[]) {
    return fileListToB64(images).pipe(
      mergeMap(files =>
        this.requestService.post<AddNewsImagesRequest, News>('/api/news/images/add', {
          news_id: id,
          images: files.map(f => ({ image: f.b64, alt: '' }))
        })
      )
    )
  }

  deleteNewsImage(id: number, index: number) {
    return this.requestService.post<DeleteNewsImageRequest, News>('/api/news/images/delete', { index, news_id: id })
  }

  publishNews(id: number, oldNewsItem: News) {
    return this.requestService.post<UpdateNewsRequest, News>('/api/news/update', {
      publish: true,
      content: oldNewsItem.content ?? '',
      title: oldNewsItem.title ?? '',
      push_text: oldNewsItem.push_text ?? '',
      news_id: id
    })
  }

  hideNews(id: number, oldNewsItem: News) {
    return this.requestService.post<UpdateNewsRequest, News>('/api/news/update', {
      publish: false,
      content: oldNewsItem.content ?? '',
      title: oldNewsItem.title ?? '',
      push_text: oldNewsItem.push_text ?? '',
      news_id: id
    })
  }
}
