import { createReducer, on } from '@ngrx/store'
import { Order } from '../../../../../common/models/order'
import { orderSelectedChangeClient, orderSelectedLoad, orderSelectedRefund } from './order-selected.actions'

export const orderSelectedFeatureKey = 'orderSelected'

export interface State {
  order?: Order
  isLoading?: boolean
}

export const initialState: State = {}

export const reducer = createReducer(
  initialState,
  on(orderSelectedLoad.start, state => ({
    ...state,
    isLoading: true
  })),
  on(orderSelectedLoad.success, orderSelectedChangeClient.success, orderSelectedRefund.success, (state, { data }) => ({
    ...state,
    order: data,
    isLoading: false
  })),
  on(orderSelectedLoad.error, () => initialState)
)
