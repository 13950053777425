import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import { Navigation } from 'common/models/navigation'
import { Event } from 'common/models/event'
import * as eventActions from './event.actions'

export const featureKey = 'Event'

export interface State extends EntityState<Event> {
  loadingEvent: boolean
  loadingEventItem: boolean
  creatingEvent: boolean
  updatingEvent: boolean
  deletingEvent: boolean
  addingImages: boolean
  deletingImage: boolean
  currentEventItem: Event | null
  navigation: Navigation | null
  publishing: boolean
  hiding: boolean
}

const selectEventId = (event: Event) => event.id ?? -1

export const adapter: EntityAdapter<Event> = createEntityAdapter<Event>({ selectId: selectEventId })

export const { selectAll } = adapter.getSelectors()

export const initialState: State = adapter.getInitialState({
  loadingEvent: false,
  creatingEvent: false,
  updatingEvent: false,
  deletingEvent: false,
  addingImages: false,
  deletingImage: false,
  currentEventItem: null,
  loadingEventItem: false,
  navigation: null,
  publishing: false,
  hiding: false
})

export const reducer = createReducer(
  initialState,
  // loading event list
  on(eventActions.getEventList.start, state => ({ ...state, loadingEvent: true })),
  on(eventActions.getEventList.success, (state, { response }) => ({
    ...adapter.setAll(response.events ?? [], state),
    navigation: response.navigation ?? null,
    loadingEvent: false
  })),
  on(eventActions.getEventList.error, state => ({ ...state, loadingEvent: false })),

  // loading event item
  on(eventActions.getEventItem.start, state => ({ ...state, loadingEventItem: true })),
  on(eventActions.getEventItem.success, (state, { event }) => ({
    ...state,
    currentEventItem: event,
    loadingEventItem: false
  })),
  on(eventActions.getEventItem.error, state => ({ ...state, loadingEventItem: false })),

  on(eventActions.clearCurrentEventItem, state => ({ ...state, currentEventItem: null })),

  // creating event item
  on(eventActions.createDraftEventItem.start, state => ({ ...state, creatingEvent: true })),
  on(eventActions.createDraftEventItem.success, (state, { event }) => ({
    ...adapter.setAll([event, ...selectAll(state)], state),
    currentEventItem: event,
    creatingEvent: false
  })),
  on(eventActions.createDraftEventItem.error, state => ({ ...state, creatingEvent: false })),

  // updating event item
  on(eventActions.updateEventItem.start, state => ({ ...state, updatingEvent: true })),
  on(eventActions.updateEventItem.success, (state, { event }) => ({
    ...adapter.setOne(event, state),
    currentEventItem: event,
    updatingEvent: false
  })),
  on(eventActions.updateEventItem.error, state => ({ ...state, updatingEvent: false })),

  //deleting event item
  on(eventActions.deleteEventItem.start, state => ({ ...state, deletingEvent: true })),
  on(eventActions.deleteEventItem.success, (state, { id }) => ({
    ...adapter.removeOne(id, state),
    deletingEvent: false,
    currentEventItem: null
  })),
  on(eventActions.deleteEventItem.error, state => ({ ...state, deletingEvent: false })),

  // hiding event
  on(eventActions.hideEvent.start, state => ({ ...state, hiding: true })),
  on(eventActions.hideEvent.success, (state, { event }) => ({
    ...adapter.setOne(event, state),
    currentEventItem: event,
    hiding: false
  })),
  on(eventActions.hideEvent.error, state => ({ ...state, hiding: false })),

  // publishing event
  on(eventActions.publishEvent.start, state => ({ ...state, publishing: true })),
  on(eventActions.publishEvent.success, (state, { event }) => ({
    ...adapter.setOne(event, state),
    currentEventItem: event,
    publishing: false
  })),
  on(eventActions.publishEvent.error, state => ({ ...state, publishing: false })),

  // adding event image
  on(eventActions.addEventImages.start, state => ({ ...state, addingImages: true })),
  on(eventActions.addEventImages.success, (state, { event }) => ({
    ...adapter.setOne(event, state),
    currentEventItem: event,
    addingImages: false
  })),
  on(eventActions.addEventImages.error, state => ({ ...state, addingImages: false })),

  // deleting event image
  on(eventActions.deleteEventImage.start, state => ({ ...state, deletingImage: true })),
  on(eventActions.deleteEventImage.success, (state, { event }) => ({
    ...adapter.setOne(event, state),
    currentEventItem: event,
    deletingImage: false
  })),
  on(eventActions.deleteEventImage.error, state => ({ ...state, deletingImage: false }))
)
