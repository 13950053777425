import { createReducer, on } from '@ngrx/store'
import {
  reviewSelectedClear,
  reviewSelectedDeleteSuccess,
  reviewSelectedLoad,
  reviewSelectedUpdateSuccess
} from './review-selected.actions'
import { Review } from 'common/models/review'

export const reviewSelectedFeatureKey = 'reviewSelected'

export interface State {
  review?: Review
  isLoading?: boolean
  isLoaded?: boolean
}

export const initialState: State = {}

export const reducer = createReducer(
  initialState,
  on(reviewSelectedLoad.start, state => ({ ...state, isLoading: true })),
  on(reviewSelectedLoad.error, state => ({ ...state, isLoading: false, isLoaded: false })),
  on(reviewSelectedLoad.success, (state, { data }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    review: data
  })),
  on(reviewSelectedUpdateSuccess, (state, { data }) => ({ ...state, review: data[0] })),
  on(reviewSelectedClear, reviewSelectedDeleteSuccess, () => initialState)
)
