import { createActionGroup, props } from '@ngrx/store'
import { DataByVisibleIdRequest } from 'common/models/data-by-visible-id-request'
import { FundsTransfer } from 'common/models/funds-transfer'
import { FundsTransferListResponse } from 'common/models/funds-transfer-list-response'

export const transfersHistoryLoad = createActionGroup({
  source: '[Transfers history] load',
  events: {
    start: props<{ req: DataByVisibleIdRequest }>(),
    success: props<{ data: FundsTransferListResponse }>(),
    error: props<{ error: string }>()
  }
})

export const revertTransfer = createActionGroup({
  source: 'Revert transfer',
  events: {
    start: props<{ transferId: number; reason: string }>(),
    success: props<{ transfer: FundsTransfer }>(),
    error: props<{ error: string }>()
  }
})

export const allErrors = [transfersHistoryLoad.error, revertTransfer.error]
