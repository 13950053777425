import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, Observable, of } from 'rxjs'
import { TicketService } from '../ticket.service'
import { tapDispatch } from '../../../../../common/utils/store/actions'
import { AppState } from '../../state'
import {
  ticketMessagesDelete,
  ticketMessagesLoad,
  ticketMessagesSendError,
  ticketMessagesSendSuccess
} from './ticket-messages.actions'
import { Store } from '@ngrx/store'
import { fileListToB64 } from '../../../../../common/utils/files/file-to-b64'
import { TicketWithMessage } from '../../../../../common/models/ticket-with-message'
import { DeleteTicketMessageRequest } from '../../../../../common/models/delete-ticket-message-request'

@Injectable()
export class TicketMessagesEffects {
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ticketMessagesLoad.start),
      mergeMap(({ ticketId }) =>
        this.ticketService.loadMessages(ticketId).pipe(
          map(data => ticketMessagesLoad.success({ data })),
          catchError(error => of(ticketMessagesLoad.error({ error })))
        )
      )
    )
  )

  send(ticketId: number, msg: string, files: File[]): Observable<TicketWithMessage> {
    return fileListToB64(files || []).pipe(
      mergeMap(b64images =>
        this.ticketService.sendMessage(ticketId, {
          message: msg,
          attachments: b64images.map(b64img => ({
            name: b64img.name,
            data: b64img.b64
          }))
        })
      ),
      tapDispatch(
        this.store,
        data => ticketMessagesSendSuccess({ data }),
        (error: string) => ticketMessagesSendError({ error })
      )
    )
  }

  delete(ticketId: number, req: DeleteTicketMessageRequest): Observable<TicketWithMessage> {
    return this.ticketService.deleteMessage(ticketId, req).pipe(
      tapDispatch(
        this.store,
        data => ticketMessagesDelete.success({ data }),
        (error: string) => ticketMessagesDelete.error({ error })
      )
    )
  }

  constructor(private actions$: Actions, private ticketService: TicketService, private store: Store<AppState>) {}
}
