import { createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Stock } from '../../../../common/models/stock'
import { stockListUpdated } from './stock.actions'

export const stockFeatureKey = 'stock'

export type State = EntityState<Stock>

const selectStockId = (stock: Stock): number => stock.id ?? 0

const sortStockById = (a: Stock, b: Stock): number => selectStockId(a) - selectStockId(b)

export const adapter = createEntityAdapter<Stock>({
  selectId: selectStockId,
  sortComparer: sortStockById
})

export const initialState: State = adapter.getInitialState()

export const reducer = createReducer(
  initialState,
  on(stockListUpdated, (state, { data }) => adapter.setAll(data.stocks || [], state))
)
