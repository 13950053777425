import { Injectable } from '@angular/core'
import { RequestService } from '../../../../common/services/request.service'
import { Config } from '../../../../common/models/config'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  load(): Observable<Config> {
    return this.request.post<void, Config>('/api/config/get', void 0)
  }
  constructor(private request: RequestService) {}
}
