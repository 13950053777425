import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { StocksResponse } from '../../../../common/models/stocks-response'
import { AppState } from '../state'
import { Store } from '@ngrx/store'
import { StockService } from './stock.service'
import { tapDispatch } from '../../../../common/utils/store/actions'
import { stockAddError, stockDeleteError, stockListUpdated, stockLoadError, stockUpdateError } from './stock.actions'
import { Stock } from '../../../../common/models/stock'

@Injectable()
export class StockEffects {
  constructor(private store: Store<AppState>, private stockService: StockService) {}

  load(): Observable<StocksResponse> {
    return this.stockService.load().pipe(
      tapDispatch(
        this.store,
        data => stockListUpdated({ data }),
        (error: string) => stockLoadError({ error })
      )
    )
  }

  add(stock: Stock): Observable<StocksResponse> {
    return this.stockService.add(stock).pipe(
      tapDispatch(
        this.store,
        data => stockListUpdated({ data }),
        (error: string) => stockAddError({ error })
      )
    )
  }

  update(stock: Stock): Observable<StocksResponse> {
    return this.stockService.update(stock).pipe(
      tapDispatch(
        this.store,
        data => stockListUpdated({ data }),
        (error: string) => stockUpdateError({ error })
      )
    )
  }

  delete(id: number): Observable<StocksResponse> {
    return this.stockService.delete(id).pipe(
      tapDispatch(
        this.store,
        data => stockListUpdated({ data }),
        (error: string) => stockDeleteError({ error })
      )
    )
  }
}
