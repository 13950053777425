import { Injectable } from '@angular/core'
import { RequestService } from '../../../../common/services/request.service'
import { GetTicketsRequest } from '../../../../common/models/get-tickets-request'
import { Observable } from 'rxjs'
import { TicketsListResponse } from '../../../../common/models/tickets-list-response'
import { Ticket } from '../../../../common/models/ticket'
import { DeleteTicketsRequestInner } from '../../../../common/models/delete-tickets-request-inner'
import { SuccessResponse } from '../../../../common/models/success-response'
import { TicketMessagesResponse } from '../../../../common/models/ticket-messages-response'
import { TicketMessageToSend } from '../../../../common/models/ticket-message-to-send'
import { TicketWithMessage } from '../../../../common/models/ticket-with-message'
import { GetTicketByIdResponse } from '../../../../common/models/get-ticket-by-id-response'
import { DeleteTicketMessageRequest } from '../../../../common/models/delete-ticket-message-request'

export const USER_TICKETS_PAGE_SIZE = 50

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  constructor(private request: RequestService) {}

  load(req: GetTicketsRequest): Observable<TicketsListResponse> {
    return this.request.post('/api/tickets', req)
  }

  loadOne(ticketId: number): Observable<GetTicketByIdResponse> {
    return this.request.post(`/api/tickets/${ticketId}`, undefined)
  }

  update(req: Ticket[]): Observable<Ticket[]> {
    return this.request.post('/api/tickets/update', req)
  }

  delete(req: DeleteTicketsRequestInner[]): Observable<SuccessResponse> {
    return this.request.post('/api/tickets/delete', req)
  }

  loadMessages(ticketId: number): Observable<TicketMessagesResponse> {
    return this.request.post(`/api/tickets/${ticketId}/messages`, undefined)
  }

  sendMessage(ticketId: number, msg: TicketMessageToSend): Observable<TicketWithMessage> {
    return this.request.post(`/api/tickets/${ticketId}/send_message`, msg)
  }

  deleteMessage(ticketId: number, req: DeleteTicketMessageRequest): Observable<TicketWithMessage> {
    return this.request.post(`/api/tickets/${ticketId}/delete_message`, req)
  }

  seize(ticketId: number): Observable<Ticket> {
    return this.request.post(`/api/tickets/${ticketId}/seize_control`, undefined)
  }

  loadUserTickets(userId: number, page: number): Observable<TicketsListResponse> {
    return this.request.post<GetTicketsRequest, TicketsListResponse>('/api/tickets', {
      pagination: { page, page_size: USER_TICKETS_PAGE_SIZE },
      filter: [{ filter_parameter: 'client_id', input_value: String(userId) }]
    })
  }
}
