import { createAction, createActionGroup, props } from '@ngrx/store'
import { CreateNewsRequest } from 'common/models/create-news-request'
import { News } from 'common/models/news'
import { NewsListResponse } from 'common/models/news-list-response'
import { UpdateNewsRequest } from 'common/models/update-news-request'
import { GetNewsByIdResponse } from 'common/models/get-news-by-id-response'

export const getNewsList = createActionGroup({
  source: 'News page load',
  events: {
    start: props<{ page: number }>(),
    success: props<{ response: NewsListResponse }>(),
    error: props<{ error: string }>()
  }
})

export const createDraftNewsItem = createActionGroup({
  source: 'News Item create',
  events: {
    start: props<{ news: Pick<CreateNewsRequest, 'title' | 'content'> }>(),
    success: props<{ news: News }>(),
    error: props<{ error: string }>()
  }
})

export const updateNewsItem = createActionGroup({
  source: 'News Item update',
  events: {
    start: props<{ data: Omit<UpdateNewsRequest, 'publish'>; oldNewsItem: News }>(),
    success: props<{ news: News }>(),
    error: props<{ error: string }>()
  }
})

export const deleteNewsItem = createActionGroup({
  source: 'News Item delete',
  events: {
    start: props<{ id: number }>(),
    success: props<{ id: number }>(),
    error: props<{ error: string }>()
  }
})

export const addNewsImages = createActionGroup({
  source: 'Add news images',
  events: {
    start: props<{ id: number; images: File[] }>(),
    success: props<{ news: News }>(),
    error: props<{ error: string }>()
  }
})

export const deleteNewsImage = createActionGroup({
  source: 'Delete news image',
  events: {
    start: props<{ id: number; index: number }>(),
    success: props<{ news: News }>(),
    error: props<{ error: string }>()
  }
})

export const publishNews = createActionGroup({
  source: 'Publish news',
  events: {
    start: props<{ id: number; oldNewsItem: News }>(),
    success: props<{ news: News }>(),
    error: props<{ error: string }>()
  }
})

export const hideNews = createActionGroup({
  source: 'Hide news',
  events: {
    start: props<{ id: number; oldNewsItem: News }>(),
    success: props<{ news: News }>(),
    error: props<{ error: string }>()
  }
})

export const getNewsItem = createActionGroup({
  source: 'Get news item',
  events: {
    start: props<{ id: number }>(),
    success: props<{ response: GetNewsByIdResponse }>(),
    error: props<{ error: string }>()
  }
})

export const clearCurrentNewsItem = createAction('Clear Current News Item')

export const allErrors = [
  getNewsList.error,
  createDraftNewsItem.error,
  updateNewsItem.error,
  deleteNewsItem.error,
  addNewsImages.error,
  deleteNewsImage.error,
  publishNews.error,
  hideNews.error,
  getNewsItem.error
]
