import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { RequestService } from '../../../../common/services/request.service'
import { DataByVisibleIdRequest } from 'common/models/data-by-visible-id-request'
import { FundsTransferListResponse } from 'common/models/funds-transfer-list-response'
import { FundsTransfer } from 'common/models/funds-transfer'
import { CancelTransferRequest } from 'common/models/cancel-transfer-request'

@Injectable({
  providedIn: 'root'
})
export class TransfersHistoryService {
  constructor(private request: RequestService) {}

  load(req: DataByVisibleIdRequest): Observable<FundsTransferListResponse> {
    return this.request.post('/api/admin/funds_transfer/list', req)
  }

  revertTransfer(id: number, reason: string): Observable<FundsTransfer> {
    return this.request.post<CancelTransferRequest, FundsTransfer>('/api/admin/cancel_transfer', {
      transfer_id: id,
      reason: reason
    })
  }
}
