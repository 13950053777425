import { createAction, createActionGroup, props } from '@ngrx/store'
import { CreateEventRequest } from 'common/models/create-event-request'
import { Event } from 'common/models/event'
import { EventListResponse } from 'common/models/event-list-response'
import { UpdateEventRequest } from 'common/models/update-event-request'

export const getEventList = createActionGroup({
  source: 'Event page load',
  events: {
    start: props<{ page: number }>(),
    success: props<{ response: EventListResponse }>(),
    error: props<{ error: string }>()
  }
})

export const createDraftEventItem = createActionGroup({
  source: 'Event Item create',
  events: {
    start: props<{
      event: Pick<CreateEventRequest, 'title' | 'place' | 'shortstory' | 'target_url' | 'date_begin' | 'content'>
    }>(),
    success: props<{ event: Event }>(),
    error: props<{ error: string }>()
  }
})

export const updateEventItem = createActionGroup({
  source: 'Event Item update',
  events: {
    start: props<{ data: Omit<UpdateEventRequest, 'publish'>; oldEventItem: Event }>(),
    success: props<{ event: Event }>(),
    error: props<{ error: string }>()
  }
})

export const deleteEventItem = createActionGroup({
  source: 'Event Item delete',
  events: {
    start: props<{ id: number }>(),
    success: props<{ id: number }>(),
    error: props<{ error: string }>()
  }
})

export const addEventImages = createActionGroup({
  source: 'Add event images',
  events: {
    start: props<{ id: number; images: File[] }>(),
    success: props<{ event: Event }>(),
    error: props<{ error: string }>()
  }
})

export const deleteEventImage = createActionGroup({
  source: 'Delete event image',
  events: {
    start: props<{ id: number; index: number }>(),
    success: props<{ event: Event }>(),
    error: props<{ error: string }>()
  }
})

export const publishEvent = createActionGroup({
  source: 'Publish event',
  events: {
    start: props<{ id: number; oldEventItem: Event }>(),
    success: props<{ event: Event }>(),
    error: props<{ error: string }>()
  }
})

export const hideEvent = createActionGroup({
  source: 'Hide event',
  events: {
    start: props<{ id: number; oldEventItem: Event }>(),
    success: props<{ event: Event }>(),
    error: props<{ error: string }>()
  }
})

export const getEventItem = createActionGroup({
  source: 'Get event item',
  events: {
    start: props<{ id: number }>(),
    success: props<{ event: Event }>(),
    error: props<{ error: string }>()
  }
})

export const clearCurrentEventItem = createAction('Clear Current Event Item')

export const allErrors = [
  getEventList.error,
  createDraftEventItem.error,
  updateEventItem.error,
  deleteEventItem.error,
  addEventImages.error,
  deleteEventImage.error,
  publishEvent.error,
  hideEvent.error,
  getEventItem.error
]
