import { createAction, createActionGroup, props } from '@ngrx/store'
import { StockChangePermissionRequest } from 'common/models/stock-change-permission-request'
import { StocksResponse } from 'common/models/stocks-response'
import { SuccessResponse } from 'common/models/success-response'

export const minionStocksLoad = createActionGroup({
  source: '[Minion stocks] load',
  events: {
    start: props<{ userId: number }>(),
    success: props<{ data: StocksResponse }>(),
    error: props<{ error: string }>()
  }
})

export const minionStocksEnabledSwitcher = createActionGroup({
  source: '[Minion stocks] switcher',
  events: {
    start: props<{ req: StockChangePermissionRequest }>(),
    success: props<{ res: SuccessResponse }>(),
    error: props<{ error: string }>()
  }
})

export const minionStocksClear = createAction('[Minion stocks] clear')

export const allErrors = [minionStocksLoad.error]
