import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { loginErrored, loginSucceed, logout, logoutSucceed } from 'common/store/jwt/jwt.actions'
import { EMPTY, mergeMap, of, tap } from 'rxjs'
import { NotifyService } from '../../app/services/notify.service'

const mainAppUrl = '/main'
const authUrl = '/auth'

@Injectable()
export class SdkJwtEffects {
  constructor(private actions$: Actions, private router: Router, private notify: NotifyService) {}

  navAfterLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSucceed),
        tap(() => {
          void this.router.navigate([mainAppUrl])
        })
      ),
    { dispatch: false }
  )

  checkRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSucceed),
      mergeMap(({ response }) => {
        if (response.client_partner) {
          this.notify.error('Вам нужно войти на сайте ewaproduct.com')
          return of(logout({ fromAllDevices: false }))
        }
        return EMPTY
      })
    )
  )

  navAfterLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutSucceed),
        tap(() => {
          void this.router.navigate([authUrl])
        })
      ),
    { dispatch: false }
  )

  showError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginErrored),
        tap(({ error }) => {
          this.notify.error(error)
        })
      ),
    { dispatch: false }
  )
}
