import { createReducer, on } from '@ngrx/store'
import { minionStocksClear, minionStocksEnabledSwitcher, minionStocksLoad } from './minion-stocks.actions'
import { Stock } from 'common/models/stock'

export const minionStocksFeatureKey = 'minionStocks'

export interface State {
  count: number
  stocks: Stock[]
  isLoading: boolean
  isLoaded: boolean
}

export const initialState: State = {
  count: 0,
  stocks: [],
  isLoading: false,
  isLoaded: false
}

export const reducer = createReducer(
  initialState,
  on(minionStocksLoad.start, state => ({ ...state, isLoading: true, isLoaded: false })),
  on(minionStocksLoad.error, state => ({ ...state, isLoading: false, isLoaded: false })),
  on(minionStocksLoad.success, (state, { data }) => ({
    ...state,
    count: data.stocks?.length ?? 0,
    stocks: data.stocks ?? [],
    isLoading: false,
    isLoaded: true
  })),
  on(minionStocksEnabledSwitcher.error, state => ({ ...state, isLoading: false, isLoaded: false })),
  on(minionStocksEnabledSwitcher.success, state => ({ ...state, isLoading: false, isLoaded: true })),
  on(minionStocksEnabledSwitcher.start, (state, { req }) => ({
    ...state,
    isLoaded: false,
    isLoading: true,
    stocks: state.stocks.map(stock =>
      stock.id === req.stock_id ? { ...stock, self_pickup_available: req.permission } : stock
    )
  })),
  on(minionStocksClear, () => initialState)
)
