import { Injectable } from '@angular/core'
import { RequestService } from '../../../../common/services/request.service'
import { Observable } from 'rxjs'
import { FundsTransferListResponse } from '../../../../common/models/funds-transfer-list-response'
import { ApproveWitdrawalRequest } from '../../../../common/models/approve-witdrawal-request'
import { SuccessResponse } from '../../../../common/models/success-response'
import { RejectWitdrawalRequest } from '../../../../common/models/reject-witdrawal-request'
import { WithdrawalListRequest } from '../../../../common/models/withdrawal-list-request'

@Injectable({
  providedIn: 'root'
})
export class WithdrawalService {
  constructor(private request: RequestService) {}

  load(req: WithdrawalListRequest): Observable<FundsTransferListResponse> {
    return this.request.post<unknown, FundsTransferListResponse>('/api/admin/withdrawals_by_status', req)
  }

  approve(req: ApproveWitdrawalRequest): Observable<SuccessResponse> {
    return this.request.post<ApproveWitdrawalRequest, SuccessResponse>('/api/admin/withdrawals/approve', req)
  }

  reject(req: RejectWitdrawalRequest): Observable<SuccessResponse> {
    return this.request.post<RejectWitdrawalRequest, SuccessResponse>('/api/admin/withdrawals/reject', req)
  }
}
